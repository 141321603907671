import { type MessageType } from './window-messaging.types';

interface Props<P> {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __as: 'props';
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __type: P;
}

type PropType = Record<string, unknown> | undefined;

type MessageCreator<T extends MessageType, P extends PropType> = P extends undefined
  ? {
      type: T;
      (): { type: T };
    }
  : {
      type: T;
      (props: P): P & { type: T };
    };

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars */
export function defineMessage<T extends MessageType>(type: T): MessageCreator<T, undefined>;
export function defineMessage<T extends MessageType, P extends PropType>(
  type: T,
  _props: Props<P>,
): MessageCreator<T, P>;
export function defineMessage<T extends MessageType, P extends PropType>(type: T, _props?: Props<P>) {
  const messageCreator: MessageCreator<T, PropType> = (props?: P) => ({
    ...(props || {}),
    type,
  });
  messageCreator.type = type;
  return messageCreator;
}
/* eslint-enable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-unused-vars */

/**
 * Helps to infer the message type.
 */
export function props<T extends Record<string, unknown>>(): Props<T> {
  return undefined as unknown as Props<T>;
}
