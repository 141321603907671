// FIXIT: babel-plugin-formatjs used in webex is incompatible with current code.
// It has a name conflict with `defineMessage` symbols existing in a codebase
// See: https://formatjs.io/docs/tooling/babel-plugin/
import { defineMessage as defineMessage_, props } from './define-message';

/**
 * All incoming / outgoing messages that uses window.postMessage
 * for integration purposes should be declared here.
 *
 * Please make sure that keys of Inbound and Outbound are unique
 * so they won't collide in union type.
 * */

export enum Inbound {
  EnterFullScreen = 'enterFullScreen',
  EventSectionInitialized = 'eventSectionInitialized',
  ExitFullScreen = 'exitFullScreen',
  ForbiddenEvent = 'forbiddenEvent',
  GetRefreshTokenResult = 'getRefreshTokenResult',
  Initialized = 'initialized',
  PollDeactivated = 'pollDeactivated',
  ReloadIntegration = 'reloadIntegration',
  SlidoUserName = 'slidoUserName',
  UnauthorizedError = 'unauthorizedError',
}

export enum Outbound {
  Authorize = 'authorize',
  GetRefreshToken = 'getRefreshToken',
  LoggedOut = 'loggedOut',
  RequestExitFullScreen = 'requestExitFullScreen',
  SelectSection = 'selectSection',
  SetTheme = 'setTheme',
  WebexUser = 'webexUser',
}

/**
 * Zoom integration related messages
 */
export const zoomParticipantAppMessages = {
  authorize: defineMessage_(Outbound.Authorize, props<{ eventUuid: string; token: string }>()),
  embeddedAppManagerInitialized: defineMessage_(Inbound.Initialized),
  setTheme: defineMessage_(Outbound.SetTheme, props<{ body: string }>()),
};

/**
 * MS Teams related messages
 * */
export const msTeamsMessages = {
  authorize: defineMessage_(Outbound.Authorize, props<{ eventUuid: string; token: string }>()),
  embeddedAppManagerInitialized: defineMessage_(Inbound.Initialized),
  pollDeactivated: defineMessage_(Inbound.PollDeactivated),
};

/**
 * Webex integration related messages
 */
export const webexMessages = {
  authorize: defineMessage_(Outbound.Authorize, props<{ eventId: number; eventUuid: string; sid: string }>()),
  embeddedAppManagerInitialized: defineMessage_(Inbound.Initialized),
  eventSectionInitialized: defineMessage_(Inbound.EventSectionInitialized, props<{ sectionUuid: string }>()),
  forbiddenEvent: defineMessage_(Inbound.ForbiddenEvent),
  reloadIntegration: defineMessage_(Inbound.ReloadIntegration),
  selectSection: defineMessage_(Outbound.SelectSection, props<{ sectionUuid: string }>()),
  setTheme: defineMessage_(Outbound.SetTheme, props<{ body: string }>()),
  slidoUserName: defineMessage_(Inbound.SlidoUserName, props<{ slidoUserName: string }>()),
  unauthorizedError: defineMessage_(Inbound.UnauthorizedError),
  webexUser: defineMessage_(Outbound.WebexUser, props<{ email: string; name: string }>()),
};

export const wallInAdminMessages = {
  enterFullScreen: defineMessage_(Inbound.EnterFullScreen),
  exitFullScreen: defineMessage_(Inbound.ExitFullScreen),
  requestExitFullScreen: defineMessage_(Outbound.RequestExitFullScreen),
};

/**
 * Embedded OAuth2 flows
 *
 * Messages are defined from the **target audience** perspective
 * (the Client requesting a Refresh Token from another one already in possession of a Refresh Token).
 */
export const embeddedOAuth2FlowMessages = {
  getRefreshToken: defineMessage_(Outbound.GetRefreshToken, props<{ clientId: string; id: string }>()),
  getRefreshTokenResult: defineMessage_(
    Inbound.GetRefreshTokenResult,
    props<{ error?: string; error_description?: string; id: string; refreshToken: string }>(),
  ),
  loggedOut: defineMessage_(Outbound.LoggedOut, props<{ autoSignIn?: boolean; clientId: string; isLogout: boolean }>()),
};
