import { type Message, type MessageType } from './window-messaging.types';

/**
 * WindowMessagingService handles parent-child communication
 * in embedded (iframe/popup) type of integration.
 *
 * For development & running on localhost, it is necessary to register target origins
 * to local envs with ports specified! f.e.:
 *
 * const windowMessagingService = new WindowMessagingService(
 * webexMessages,
 * [EnvironmentConfig.SLIDO_ADMIN_URL, 'https://admin.local.slido-staging.com:3005' ],
 * onMessage);
 *
 * */
export class WindowMessagingService {
  private iframeChildElement: HTMLIFrameElement | undefined = undefined;

  private targetWindow?: Window;
  private readonly onMessage?: (message: Message) => void;
  private targetOrigins: string[];
  private readonly validMessageNames: MessageType[];

  /**
   * Constructs WindowMessagingService
   *
   * @param validMessages - object of all valid messages for current integration
   * @param targetOrigins - array of valid target origins
   * @param onMessage - handler for `message` events on `window`
   * */
  constructor(
    validMessages: { [key: string]: Message },
    targetOrigins: string[],
    onMessage?: (message: Message) => void,
  ) {
    this.validMessageNames = Object.values(validMessages).map((m) => m.type);
    this.onMessage = onMessage?.bind(this);
    this.targetOrigins = targetOrigins;
    this.registerListener();
  }

  registerListener(): void {
    window.addEventListener('message', this.messageHandler);
  }

  dispose(): void {
    window.removeEventListener('message', this.messageHandler);
  }

  /**
   * Posts message for all target origins to target window.
   *
   * @param message - message to be sent to target window
   * */
  post(message: Message): void {
    const jsonMessage = JSON.parse(JSON.stringify(message)) as Message;
    this.targetOrigins.forEach((origin) => {
      this.getTargetWindow()?.postMessage(jsonMessage, origin);
    });
  }

  /**
   * Registers iframe element that messaging service uses as default target for posting messages.
   *
   * @param iframeChildElement - iframe element
   * */
  registerIframeElement(iframeChildElement: HTMLIFrameElement): void {
    this.iframeChildElement = iframeChildElement;
  }

  registerTargetWindow(targetWindow: Window): void {
    this.targetWindow = targetWindow;
  }

  get isIframeElementRegistered(): boolean {
    return Boolean(this.iframeChildElement);
  }

  private messageHandler = (event: MessageEvent<Message>) => {
    if (this.isValidMessage(event.data) && this.targetOrigins.includes(event.origin)) {
      this.onMessage?.(event.data);
    }
  };

  private getTargetWindow(): Window | undefined {
    if (this.iframeChildElement) {
      return this.iframeChildElement.contentWindow || undefined;
    } else if (this.targetWindow) {
      return this.targetWindow;
    }

    // window.opener is used for popup windows, window.parent is used for iframe
    return (window.opener as Window) ?? window.parent;
  }

  private isValidMessage(message: Message): boolean {
    return this.validMessageNames.includes(message.type);
  }
}
